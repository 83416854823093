.MuiDrawer-root {
  .MuiPaper-root {
    @media screen and (max-width: 480px) {
      margin-left: 0;
    }
  }
}

.font-bold {
  font-weight: bold !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}

.breadcrumb-wrapper {
  @media screen and (max-width: 500px) {
    flex: 1;
  }
}

.avatar-wrapper {
  @media screen and (max-width: 500px) {
    flex: 0 0 0;
  }
}

//breadcrumb style for responsive
.bredcrumb-main {
  @media screen and (max-width: 500px) {
    width: 100%;
    overflow: hidden;
  }

  li {
    font-size: 12px;

    span {
      font-size: 12px;
    }

    @media screen and (max-width: 567px) {
      line-height: 20px !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  h6 {
    margin-top: 5px;
  }
}

//main-header-toolbar styles for responsive mobile view
.main-header-toolbar {
  padding-left: 0 !important;
  flex-direction: row !important;

  @media screen and (max-width: 567px) {
    padding: 0 10px !important;
  }
}

//for extra small buttons
.xs-small {
  min-height: 35px !important;
  padding: 0px 8px !important;
  font-size: 12px !important;
  white-space: nowrap !important;
}

// for page title label for mobile
.page-header-label {
  @media screen and (max-width: 567px) {
    font-size: 15px !important;
  }
}

//---------------------- pages wise CSS

//System admin / connect-access page
.user-access-dropdown {
  max-width: 400px;

  @media screen and (max-width: 567px) {
    max-width: 200px;
  }
}

.connect-access-save-button {
  justify-content: flex-end;

  @media screen and (max-width: 567px) {
    justify-content: center !important;
  }
}

.additional_menu_title {
  @media screen and (max-width: 567px) {
    font-size: 16px !important;
  }
}

//CRM / Deals page
.deals-board-change-view {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 12px;
  flex: 1;
  justify-content: flex-end;
  position: relative;

  .change-view-tabs {
    .tab-button {
      padding-left: 10px;
    }
  }
}

.crm-location-header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  height: 100%;
  z-index: 9999;

  @media screen and(max-device-width:767px) and (max-height: 667px) {
    &.sms-view {
      opacity: 1;
      top: 76px;
      background: #fff !important;
    }
  }
}

.crm-location-header {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 0px;
  // right: 0;
  right: 270px;
  max-height: calc(100vh);
  overflow: auto;
  z-index: 9999;
  background: #ffffff !important;
  box-shadow: 0 1px 18px rgba(0, 0, 0, 0.2) !important;
  padding: 12px;
  width: 100%;
  max-width: 306px;

  .close-filter-button {
    display: block;
    position: absolute;
    right: 10px;
    top: 5px;
  }

  @media screen and(max-device-width:1023px) {
    left: 0;
    right: 0;
    max-width: 100%;
  }

  &.sms-view {
    top: 100px;
    max-width: 350px;
    left: 285px;
    overflow: auto;
    max-height: calc(100vh - 110px);

    @media screen and(max-device-width:1199px) {
      left: 20px;
      max-width: 380px;
    }

    @media screen and(max-device-width:1023px) {
      left: unset;
      right: 15px;
      max-width: 96%;
    }
  }

  & > div {
    margin-bottom: 6px;
    flex: 0 0 100%;
  }

  .MuiFormControl-root {
    width: 100%;
    margin-top: 0;

    // @media only screen and (max-width: 1024px) {
    //   width: 200px;
    // }

    // @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    //   width: 250px;
    // }

    // @media only screen and (min-width: 1367px) {
    //   width: 280px;
    // }
  }
}

.deals-board-card-header {
  width: 100% !important;
  background-color: #dce0e1 !important;

  h6 {
    white-space: normal;
    padding: 10px 7px !important;
  }
}

.kanban-main-view {
  height: calc(100vh - 260px);

  .react-kanban-board {
    margin-bottom: 20px;
    height: 100% !important;
    overflow-y: auto !important;
  }
}

.react-kanban-board > div {
  display: flex;

  & .react-kanban-column {
    height: unset !important;

    & > div:nth-child(2) {
      height: 100% !important;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}

.react-kanban-column > div:nth-child(2) > div > div {
  width: 100%;
}

//deals page chat area
.crm-deals-chat-container {
  margin-top: 20px;
  overflow: auto !important;
  height: calc(100vh - 285px);

  @media screen and (max-height: 567px) {
    height: calc(100vh - 200px);
  }
}

.chat-status-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 567px) {
    display: block;
  }
}

.message-row {
  padding: 5px 10px 0 10px;
  display: grid;
  grid-template-columns: 70%;
  margin-top: 15px;

  @media screen and (max-width: 567px) {
    grid-template-columns: 100%;
  }

  &.end-message-chatbox {
    justify-content: end;

    > .message-content {
      justify-items: end;
      grid-column-gap: 5px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      > .message-text {
        background: #1fa0ef;
        color: #fff !important;
        border: 1px solid #eee;
        border-radius: 14px;
        word-break: break-word;

        > .MuiTypography-root {
          color: #fff;
        }

        > .message-time {
          display: flex;
          justify-content: flex-end;
          position: absolute;
          bottom: -18px;
          right: 6px;
          width: 200px;
        }
      }
    }
  }

  &.end-notes-chatbox {
    justify-content: end;

    > .message-content {
      justify-items: end;
      grid-column-gap: 5px;
      display: flex;
      flex-direction: row-reverse;

      > .message-text {
        background: rgba(48, 57, 65, 0.5);
        color: #fff !important;
        border: 1px solid #eee;
        border-radius: 14px;
        word-break: break-word;

        > .MuiTypography-root {
          color: #fff !important;
        }

        > .message-time {
          color: #303941 !important;
          display: flex;
          justify-content: flex-end;
          position: absolute;
          bottom: -18px;
          right: 6px;
          width: 120px;
        }
      }
    }
  }

  &.start-message-chatbox {
    justify-items: start;

    > .message-content {
      display: flex;
      flex-direction: row;
      justify-items: start;
      grid-column-gap: 5px;
      grid-template-columns: 48px 1fr;

      @media screen and (max-width: 567px) {
        grid-template-columns: 35px 1fr;
      }

      > .message-text {
        background: rgba(48, 57, 65, 0.1);
        color: #111;
        border: 1px solid #eee;
        border-radius: 14px;

        > .message-time {
          position: absolute;
          left: 2px;
          bottom: -20px;
          width: 120px;
        }
      }
    }
  }

  > .message-content {
    display: grid;

    > .avatar-image {
      width: 40px;

      @media screen and (max-width: 567px) {
        width: 30px;
      }
    }

    > .message-text {
      display: flex;
      flex-direction: column;
      padding: 9px 14px;
      font-size: 16px;
      margin-bottom: 5px;
      position: relative;

      > .message-time {
        font-size: 11px;
        color: #303941 !important;
        width: 100%;
        margin-top: 10px;
        // position: absolute;
        // top: 30px;
      }
    }

    @media screen and (max-width: 567px) {
      width: 100%;
    }
  }
}

.reply-container {
  .reply-text-box {
    font-size: 16px;
    margin: 0 5px 0 12px;
    flex: 1;
  }

  .send-reply-option {
    padding: 10px 0px;
    width: 110px;
    background-color: #0b8cfb;

    .MuiSelect-select {
      color: #fff;
    }

    .MuiSelect-icon {
      display: flex;
      right: 0;
      font-size: 20px !important;
      color: #fff;
    }
  }
}

.location-tabs {
  .MuiTabs-scroller {
    @media screen and (max-width: 991px) {
      overflow-x: auto !important;

      .MuiButtonBase-root {
        flex: 0 0 auto;
        height: 36px;
        padding: 0 16px;
        font-size: 14px;
        white-space: nowrap;
      }
    }
    @media screen and (max-width: 567px) {
      overflow-x: auto !important;

      .MuiButtonBase-root {
        flex: 0 0 50%;
        height: 50px;
        font-size: 14px;
        white-space: normal;
      }
    }
  }
}

// location - crm - communication delay
#menu-max_walkins_time {
  .MuiMenu-paper {
    max-height: 200px;
  }
}

.container-communication-delay {
  overflow: visible;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 20px;

  .communication-delay-item {
    width: 100%;
    max-width: 650px;
    // width: 650px !important;
    display: flex;
    flex-direction: row;
    // overflow: hidden;
    padding: 10px 0px;
    align-items: center;
    line-height: 36px;
    flex-wrap: wrap;

    .inner-wrapper {
      display: flex;
      align-items: center;
    }

    .time-picker {
      margin-left: 10px !important;
      width: 100px;
    }

    .MuiTypography-root {
      line-height: 36px;
    }
  }

  .time-zone-picker {
    width: 100%;
    max-width: 300px;
  }
}

// sola pro - general - services
.services-container {
  flex-wrap: wrap;
  display: flex !important;
  flex-direction: row !important;

  @media screen and (max-width: 567px) {
    .MuiChip-root {
      margin: 5px 0px;
    }
  }
}

.rc-time-picker-panel {
  z-index: 1400 !important;
}

.time-picker-child {
  margin-right: 7px;

  .lease-stepper {
    @media screen and (max-width: 567px) {
      overflow-x: scroll;
    }
  }
}

.acount-setup-stepper {
  @media screen and (max-width: 1080px) {
    overflow-x: scroll;
  }
}

// sola pro survey satisfaction css
.survey-tabs {
  .MuiTabs-scroller {
    @media screen and (max-width: 767px) {
      overflow-x: auto !important;

      .MuiButtonBase-root {
        flex: 0 0 50%;
        height: 50px;
      }
    }
  }
}

// calendar with calendar icon
.calendar-with-icon {
  width: 50px !important;
  height: 44px !important;
  background-color: #ccc;
  border-radius: 0 5px 5px 0;
  margin-left: -5px;
  color: rgba(0, 0, 0, 0.7);
  padding-top: 2px;
}

.select-container {
  #approved {
    padding-left: 0 !important;
  }

  .MuiSelect-icon {
    display: block;
  }

  &.ideal-timing {
    @media screen and (min-width: 768px) {
      padding-top: 2.3rem !important;
    }
  }
}

.imageWithDeleteIcon {
  position: relative !important;
  margin-right: 10px !important;

  > span {
    position: absolute !important;
    right: -10px;
    top: -12px;
  }

  .removeBtnClass {
    background: #fff !important;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.5);
    padding: 4px !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.textarea {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.height45 {
  height: 45px;
}

.marginLeft {
  margin-left: 5px;
}

// .marginRight {
//   margin-right: 5px;
// }

.top-3 {
  top: -3px;
}

.colorWhite {
  label {
    font-size: 25px !important;
  }

  input {
    font-size: 16px !important;
  }

  label,
  input {
    color: #fff !important;
    line-height: 1.5;
  }

  .Mui-focused {
    color: #fff !important;
  }
}

.helper-info-texts {
  color: #fff !important;
  line-height: 1.5;
  font-size: 16px;
}

.checkbox-while-lable {
  span {
    color: #fff !important;
  }
}

.select-while-lable {
  input {
    color: #fff !important;
  }
}

.ReactModal__Overlay {
  z-index: 1300 !important;
}

.required_star {
  color: #db3131;
  margin-left: 2px;
}

.location-accordion {
  background-color: #d3d3d3 !important;
  height: 45px !important;
  min-height: 45px !important;
}

.end-of-day-time {
  input {
    height: 40px;
  }

  .rc-time-picker-clear {
    top: 8px;
  }
}

// common thumbnail img.
.thumbnail-img {
  cursor: pointer;
}

.ril__image {
  cursor: pointer;
}

.imageWithDeleteIcon {
  img {
    cursor: pointer;
  }
}

// ----------------------------------------------
.customDropdown {
  position: absolute;
  right: 70px;
  width: 270px;
  top: 50px;

  .drop-h6 {
    position: relative;
    padding: 6px 22px 6px 6px;
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin: 2px;
    }

    .MuiChip-root {
      height: 24px;
      font-size: 12px;
      text-transform: capitalize;
    }

    .drop-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .customDropdown-content {
    .MuiTreeItem-label {
      .MuiTypography-root {
        font-size: 12px;
      }

      .MuiFormControlLabel-root {
        display: flex;
      }

      .MuiCheckbox-root {
        padding: 4px;
      }
    }

    .MuiPaper-root {
      padding-bottom: 40px;
      max-height: 200px;
      overflow: auto;

      .apply-button {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.1);
        text-align: right;

        .MuiButton-root {
          padding: 6px;
          min-height: 30px;
          margin: 5px;
          margin-right: 16px;
          min-height: 24px;
        }
      }
    }
  }
}

.listing-table-head {
  th {
    padding: 10px;
  }
}

.listing-table-cell {
  td {
    padding: 10px;
  }
}

.ecllips-class {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
  color: rgb(70, 69, 69);
}

.revenue-line-chart {
  .MuiPaper-root {
    height: 100%;
  }
}

.MuiTextField-root.MuiFormControl-root {
  background-color: transparent !important;
}

.editorHeight > div > .quill > .ql-container {
  height: 14em !important;
}

.overflowVisible .MuiPaper-root {
  overflow: visible !important;
}

#walk-in {
  .imageWithDeleteIcon {
    margin-top: 20px;
  }
}

.active-lease-box {
  border: 1px solid #344767 !important;
  background: #deecf9 !important;
}

// md editor custom
.custom-mdEditor-height .ql-editor {
  min-height: 100px;

  @media screen and (max-width: 767px) {
    min-height: 100px;
  }
}

.location-list-data {
  max-height: 340px;
}

.listing-common-table {
  .Mui-checked {
    .MuiSvgIcon-root {
      background-color: #1a73e8;
    }
  }

  .MuiCheckbox-indeterminate {
    .MuiSvgIcon-root {
      background-color: #1a73e8;
      color: #fff;
      border-color: #1a73e8;
    }
  }
}

.unit-autocomplete {
  .MuiAutocomplete-inputRoot {
    padding: 5px !important;
  }
}

.timPickerClass {
  position: relative;

  .MuiInputLabel-root {
    position: absolute;
    left: 36px;
    z-index: 1;
    background: #fff;
    top: 19px;
    font-size: 12px;
  }

  .rc-time-picker {
    display: block;
    margin-bottom: 8px;
  }

  .rc-time-picker-input {
    height: 45px;
    width: 100%;
    border-radius: 0.375rem;
  }

  .rc-time-picker-clear {
    top: 10px;
  }

  .rc-time-picker-input:focus-visible {
    outline: none;
    border-color: #1a73e8;
  }
}

.board-count {
  font-size: 0.8rem;
  font-weight: 300;
  // margin-right: 10px !important;
}

.kanban-service-filter {
  width: 200px;
  margin-left: 8px;
  display: inline;

  .MuiInput-root {
    min-height: 38px;
    background: #fff;

    input {
      padding-left: 8px !important;
    }
  }
}

.pl-25 {
  padding-left: 25px !important;
}

.p-relative {
  position: relative !important;
}

.login-component {
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}

.add-new-btn,
.add-new-btn:hover {
  background-color: #d3d3d3 !important;
  margin-top: 8px !important;
}

/** loader **/
.loader-triangle-1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #1a71e8 transparent transparent transparent;
  margin: 0 auto;
  animation: loader-triangle-1-anime 1s ease-in-out infinite normal;
}

.loader-triangle-2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent transparent #3a373f transparent;
  margin: -50px auto 0;
  animation: loader-triangle-2-anime 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes loader-triangle-1-anime {
  0% {
    transform: rotate(-360deg);
  }

  100% {
  }
}

@keyframes loader-triangle-2-anime {
  0% {
    transform: rotate(360deg);
  }

  100% {
  }
}

.image-grid {
  padding: 20px;

  .image-item {
    height: 150px !important;
    width: 170px;
    margin-right: 10px;
    margin-top: 10px;
    vertical-align: middle;

    .action-btn {
      background-color: #00000070;
      margin-left: 2px;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.lead-details-main {
  height: calc(100vh - 124px);
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  .lead-detail-left {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: 900px) {
      padding: 10px;
      height: calc(100vh - 100px);
    }
  }

  .cards-list-div {
    height: 100%;
    position: relative;
    border-right: 1px solid rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
  }

  .lead-detail-right {
    @media screen and (max-width: 992px) {
      position: fixed;
      width: 100%;
      right: -100%;
      top: 75px;
      transition: all 0.3s ease-in-out;
      z-index: 9;
      background: #f0f2f5;
      height: calc(100vh - 100px);
      overflow: auto;
      padding: 10px;

      &.active {
        right: 0;
      }
    }
  }

  .status-select {
    position: sticky;
    top: -7px;
    z-index: 9;
    padding: 0 5px 5px 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .cards-list {
    // height: 100%;
    height: calc(100vh - 310px);
    padding: 0 5px 0 5px;
    overflow: auto;
    display: list-item;
    &.all-cards {
      height: calc(100vh - 275px);
      height: 100%;
      flex: auto;
    }
    & > div:first-child {
      margin-bottom: 12px;
    }
  }

  .lead-card {
    // max-height: 150px;
    margin: 8px 0 0 0;
    width: 100%;
    border-radius: 0.5rem !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    box-shadow: none !important;

    .deal-name {
      max-width: 90%;
      font-size: 18px;
    }
  }

  .active-card {
    border: 1px solid #1a73e8 !important;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06) !important;
    background-color: rgba(26, 115, 232, 0.12);
  }
}

.lead-tabs {
  width: 100%;
  border-radius: 0.5rem !important;
  padding: 0 !important;

  .MuiTabs-indicator {
    background: transparent;
    box-shadow: none;
    border-bottom: 2px solid #227bea;
    border-radius: 0px;
  }
}

.lead-tabs-msg {
  width: 60%;
  border-radius: 0.5rem !important;
  padding-bottom: 0 !important;
  background-color: #fff !important;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .MuiTabs-indicator {
    background: transparent;
    box-shadow: none;
    border-bottom: 2px solid #227bea;
    border-radius: 0px;
  }
}

.tab-section {
  background-color: #fff;
  // height: 100%;
  height: calc(100vh - 300px);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.lead-tags {
  background-color: #55aff6;
  padding: 2px 10px 2px 10px;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 1rem;
}

.lead-tags-2 {
  background-color: #1a73e8;
  padding: 2px 10px 0px 10px;
  color: #fff !important;
  font-weight: 400 !important;
  border-radius: 1rem;
  font-size: 13px !important;
}

.activity-container {
  width: 100%;
  height: calc(100% - 45px);
  overflow: auto;

  .MuiBox-root {
    padding: 10px !important;
  }
}

.activity-card {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0 !important;
  margin-top: 5px;
  position: relative;
  cursor: pointer;
}

.activity-card-date {
  position: absolute;
  top: 8px;
  right: 14px;
}

.message-container {
  width: 100%;
  height: 100%;

  & > .MuiBox-root {
    height: calc(100% - 200px);
    padding: 0px !important;
  }

  .messages {
    height: 100%;
    overflow: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.checkbox-list-accordian {
  padding-left: 0 !important;

  .mui-checkbox-group {
    overflow: auto;
    height: 100vh;
    max-height: 400px;
    width: 100%;
    padding: 0px 16px;
  }
}

.msg-input {
  & > .MuiBox-root {
    padding: 0px !important;
  }

  .msg-input-box {
    .MuiInputBase-root {
      border-radius: 0 !important;
    }
  }

  .msg-panel {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 8px;

    .sms_stop_text {
      margin-right: auto;
      font-size: 12px;
      font-weight: bold;
      color: red;
    }
  }
}

.notes-input {
  height: calc(100% - 175px);
  overflow-y: scroll;

  & > .MuiBox-root {
    padding: 0px !important;
  }

  .notes-input-box {
    .MuiInputBase-root {
      border-radius: 0 !important;
    }
  }

  .note-panel {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .msg-box {
    border-radius: 0.5rem !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(255, 255, 255, 1);
    padding: 16px;
    margin: 3px 0;
  }
}

.search-xs-small {
  min-height: 35px !important;
  // padding: 0px 8px !important;
  font-size: 12px !important;
  white-space: nowrap !important;
}

.cards-list-tabpanel {
  & > .MuiBox-root {
    padding: 0px !important;
    height: 100%;
  }

  .msg-input-box {
    .MuiInputBase-root {
      border-radius: 0 !important;
    }
  }
}

.cards-list-tabpanel-filter {
  height: calc(100% - 248px);

  & > .MuiBox-root {
    padding: 0px !important;
    height: 100%;
  }

  .msg-input-box {
    .MuiInputBase-root {
      border-radius: 0 !important;
    }
  }
}

table {
  tbody {
    td {
      p,
      div {
        color: inherit !important;
      }
    }
  }
}

// custom table css ---

.MuiTableContainer-root {
  max-height: calc(100vh - 190px);
  min-height: 200px;
  padding-bottom: 90px;
  overflow-x: scroll !important;

  table {
    thead {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 9;
      box-shadow: 0 5px 5px -6px rgba(0, 0, 0, 0.15);
      height: 50px;
    }

    & + .MuiBox-root {
      position: absolute;
      bottom: 12px;
      left: 0px;
      border-radius: 0 0 10px 10px;
      width: calc(100% - 12px);
      background: #fff;
    }
  }
}

.MuiModal-root {
  .MuiTableContainer-root {
    padding-bottom: 0px;

    table {
      & + .MuiBox-root {
        position: relative;
        bottom: 0;
        width: 100%;
      }
    }
  }
}

#filter-popover {
  .MuiPopover-paper {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.25);
    background: #fff;
  }
}

.fixed-button {
  position: fixed !important;
  bottom: 20px;
  right: 20px;
  z-index: 9;
}

.MuiCheckbox-root {
  &.Mui-disabled {
    opacity: 0.3;
  }
}

.table-box-subcontainer {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.repeat-days {
  .days {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ccc;
    border-radius: 50%;
    flex: 0 0 30px;
    margin-right: 10px;
    cursor: pointer;
  }

  .selected {
    background: #4372e0;
    color: #fff;
  }
}

.export-accordion {
  background-color: #d3d3d3 !important;
  height: 45px !important;
  min-height: 45px !important;

  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    border-color: #1a73e8 !important;
  }
}

.custom-filter-column-fields {
  .MuiSelect-select {
    &.MuiSelect-standard {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.appointment-container {
  text-align: center;

  .appointment-section {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .time-slots {
    max-height: 340px;
    overflow-y: auto;

    .time {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 3px;
      margin: 5px 15px;
      border-radius: 5px;
      cursor: pointer;
    }

    .active {
      background: rgba(233, 30, 99, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
}

.frequency {
  width: 200px;
}

.deal-open,
.deal-done {
  position: absolute !important;
  top: 15px;
  right: 15px;
  color: rgba(128, 128, 128, 1);
}

.deal-done {
  color: rgba(0, 128, 0, 1);
}

.deal-open:hover svg {
  border-color: #1a73e8 !important;
}

.deal-done.Mui-checked:hover {
  opacity: 0.5;
}

.deal-status-name {
  position: sticky;
  top: -1px;
  z-index: 1;
  background: #f0f2f5;
  padding-top: 5px;
  padding-bottom: 5px;
}

.update-my-sola-biography-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
}

.deal-header-checkbox {
  svg {
    border-color: #1a73e8 !important;
  }
}

.kanban-card-box {
  position: relative;

  .broadcast-chk {
    position: absolute;
    right: 0;
    top: 8px;
  }

  .broadcast-chk-opacity {
    opacity: 0;
    transition: all 0.2s ease-in-out;

    &.Mui-checked {
      opacity: 1;
    }
  }

  &:hover {
    .MuiCheckbox-root {
      opacity: 1;
    }
  }

  .MuiList-root {
    .MuiTypography-root {
      padding-right: 20px;
    }
  }
}

#template-settings {
  table {
    table-layout: fixed;

    thead {
      th:first-child {
        width: auto;
      }
    }
  }

  .template-message-column {
    width: 100%;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
      max-width: 100%;
      color: rgb(0, 0, 0);
    }
  }

  // .container-template-settings {
  //   border: 1px solid #ddd;
  //   border-radius: 6px;

  //   .templates-list {
  //     padding: 10px;

  //     .template-list-item {
  //       background: #fff;
  //       padding: 10px 16px;
  //     }

  //     .template-list-item:nth-child(odd) {
  //       background: #f5f5f5;
  //     }
  //   }
  // }
}

.template-content-wrapper {
  position: relative;

  .add-token-btn {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 2;
    background-color: #e9e9e9;
    padding: 3px;
    min-width: unset;
    min-height: unset;
  }

  textarea {
    padding-right: 32px;
  }

  .placeholder-dorpdown {
    position: absolute;
    right: 5px;
    z-index: 1;
    background: #fff;
    top: 30px;

    .MuiOutlinedInput-root {
      height: 40px;
      width: 200px;
    }
  }
}

.sms-template-popover {
  .sms-template-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 450px;
  }

  hr {
    background-color: #00000061;
  }

  .MuiPaper-elevation {
    padding: 0px;
    background: #fff !important;
    box-shadow: 0 10px 32px 0 rgba(0, 0, 0, 0.2);

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 500px;
      left: auto !important;
      right: 0 !important;
    }

    & > .MuiBox-root {
      flex-direction: column;
      width: 500px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      .sms-template-header {
        background: #0081e3;

        .sms-header-title {
          font-size: 18px;
          font-weight: 700;
          color: #fff !important;
        }
      }

      & > .MuiBox-root {
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 4px 8px;
        width: 100%;

        .MuiTextField-root {
          width: 100%;
        }
      }

      .sms-template-body {
        height: 200px;
        overflow: auto;

        ul {
          height: 100%;
          width: 100%;

          li {
            cursor: pointer;
            padding: 6px;
          }
        }
      }
    }
  }
}

.placeholder-broadcast-sms-dorpdown-wrapper {
  position: relative;

  .placeholder-broadcast-sms-dorpdown {
    width: 200px;
    position: absolute;
    top: -57px;
    right: 10px;
    background: #fff;
    z-index: 1;

    .MuiOutlinedInput-root {
      height: 40px;
    }
  }
}

.placeholder-location-sms-dorpdown-wrapper {
  position: relative;

  .placeholder-location-sms-dorpdown {
    width: 200px;
    position: absolute;
    right: 40px;
    background: #fff;
    z-index: 1;

    .MuiOutlinedInput-root {
      height: 40px;
    }
  }
}

.drip-email-editor-wrapper {
  position: relative;

  .drip-email-editor {
    position: absolute;
    right: 0;
    top: -40px;
  }
}

.parent-common-table-box {
  @media screen and (max-width: 1023px) {
    padding: 16px 8px !important;
    flex-wrap: wrap;

    .child-common-table-box-left {
      flex: auto;
      margin-top: 10px;
    }

    .child-common-table-box-right {
      flex: auto;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start !important;
    padding: 8px 0px !important;

    .child-common-table-box-left {
      flex: auto;
      margin-bottom: 10px;
    }

    .child-common-table-box-right {
      flex: auto;
      justify-content: flex-start;
      margin-left: 0;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .child-common-table-box-right {
    .mobile-exports {
      display: none;

      @media screen and (max-width: 767px) {
        display: flex;

        .MuiButton-root {
          min-width: unset;

          .MuiButton-startIcon {
            margin: 0 auto !important;
          }
        }
      }
    }

    .desktop-exports {
      display: flex;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}

.MuiTextField-root.filter-input {
  background-color: #fff !important;
  height: 100%;

  .MuiInput-root {
    height: 100%;
    padding: 4px 10px 5px;
  }
}

.MuiTextField-root.sms-filter-input {
  background-color: #fff !important;
  height: 36px;
  margin-top: 6px;

  .MuiInput-root {
    height: 100%;
    margin-left: 8px;
  }
}

.phone-changed {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 20%;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    z-index: 99999;
    background: #344767;
  }

  &::after {
    position: absolute;
    content: "";
    width: 20%;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    z-index: 99999;
    background: #344767;
  }

  .message-text {
    font-size: 12px;
    text-align: center;
    max-width: 50%;
    margin: 0 auto;
    position: relative;
    margin-top: 25px;
  }
}

.deal-title-wrapper {
  display: flex;
  @media screen and (max-width: 480px) {
    font-size: 18px !important;
    line-height: normal !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px !important;

    .sms-view-deal-title {
      font-size: 18px;
    }

    .sms-view-deal-title-message {
      font-size: 12px;
    }
  }
}

.status-container{
  @media screen and (max-width: 767px) {
    flex-direction: column;
    .status-wrapper {
      max-width: none;
      & > div {
        max-width: none;
      }
    }
  }

}

.status-wrapper {
  @media screen and (max-width: 480px) {
    flex: 0 0 50%;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;
    padding-right: 12px;

    .MuiTypography-root {
      font-size: 13px;
    }

    .MuiFormControl-root {
      margin-top: 0;
    }
  }
}

.location-wrapper {
  overflow: hidden;
  display: flex;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 50%;

    .location-name-label {
      font-size: 13px;
    }
  }

  .sms-view-location {
    width: 97%;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.message-view-tab {
  @media screen and (max-width: 480px) {
    .MuiBox-root {
      font-size: 13px;
    }
  }
}

// Css for selected Items (23-03-2023
.selectItems .MuiListItemButton-root:focus {
  background: rgba(0, 0, 0, 0.1) !important;
}

// @media only screen and (max-width: 760px),
// (min-device-width: 767px) and (max-device-width: 1024px) {

//   /* Force table to not be like tables anymore */
//   table,
//   thead,
//   tbody,
//   th,
//   td,
//   tr {
//     display: block !important;
//     max-width: 100% !important;

//     /*
//     Label the data
//     */
//     tr {
//       @for $i from 1 through 100 {
//         td:nth-of-type(#{$i}):before {
//           content: attr(data-text);
//           font-weight: bold;
//         }

//         $i: $i + 1;
//       }
//     }
//   }

//   /* Hide table headers (but not display: none;, for accessibility) */
//   thead tr {
//     position: absolute;
//     top: -9999px;
//     left: -9999px;
//   }

//   tr {
//     border: 1px solid #ccc;
//   }

//   td {
//     /* Behave  like a "row" */
//     border: none;
//     border-bottom: 1px solid #ededed !important;
//     position: relative;
//     padding-left: 50% !important;
//     width: 100%;

//     &>div {
//       color: inherit !important;
//       width: 100%;
//       white-space: nowrap;
//       overflow: visible !important;
//       text-overflow: unset !important;
//       display: block !important;
//       white-space: unset !important;
//     }
//   }

//   td:before {
//     /* Now like a table header */
//     position: absolute;
//     /* Top/left values mimic padding */
//     top: 6px;
//     left: 6px;
//     width: 45%;
//     padding-right: 10px;
//     white-space: nowrap;
//   }
// }

// 27/03/2023  add applicant stepper scss
.MuiStepConnector-root.Mui-active {
  color: #9fc9ff !important;
}

.applicant-step-label {
  span.Mui-active,
  .MuiStepLabel-label.Mui-completed {
    color: #000 !important;
    font-weight: 600 !important;
  }

  .MuiStepLabel-label {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .MuiStepIcon-root.Mui-active,
  .MuiStepIcon-root.Mui-completed {
    // background-color: #1a73e8 !important;
    fill: #1976d2 !important;
    stroke: #fff !important;
    stroke-width: unset !important;
    width: 20px;
    height: 20px;
    box-shadow: none !important;
    border-radius: 0px;
  }

  .MuiStepIcon-root.Mui-disabled {
    stroke-width: unset !important;
  }

  .MuiStepIcon-root {
    fill: rgba(0, 0, 0, 0.38) !important;
    stroke: #fff !important;
    stroke-width: unset !important;
    width: 20px;
    height: 20px;
    box-shadow: none !important;
  }
}

.lease-box {
  @media screen and (max-width: 667px) {
    height: 100%;

    .inner-box {
      font-size: 14px;
      height: 100%;
      padding: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

// New changes at 31-03-2023
.selectItems .MuiListItemButton-root:hover {
  background: #1919194d !important;
  color: #fff;
}

.web-calling-loader {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: block;
  margin: 5px auto;
  position: relative;
  color: #1a73e8;
  box-sizing: border-box;
  animation: webcallinganimloader 1s linear infinite alternate;
}

@keyframes webcallinganimloader {
  0% {
    box-shadow: -15px -4px, -5px 0, 5px 0, 15px 0;
  }

  33% {
    box-shadow: -15px 0px, -5px -4px, 5px 0, 15px 0;
  }

  66% {
    box-shadow: -15px 0px, -5px 0, 5px -4px, 15px 0;
  }

  100% {
    box-shadow: -15px 0, -5px 0, 5px 0, 15px -4px;
  }
}

.web-calling {
  max-width: 140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.22) !important;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 12px;

  .avatar-img {
    margin-bottom: 24px;
  }

  .call-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.no-notification {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-container {
  width: 400px;
  position: absolute;
  right: 0;
  background-color: #fff !important;
  padding: 10px;
  top: 65px;
  height: 450px;
  overflow-y: auto;

  .mark-as-read {
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .read-indicator {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: red;
    right: 6px;
    top: 13px;
  }

  .notification-card-date {
    position: absolute;
    bottom: -3px;
    right: 5px;
  }
}

.notification-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
}

.notification-indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #0081e3;
  position: absolute;
  top: 12px;
  right: 10px;
}

.notification-indicator-count {
  background-color: #de350b;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  right: 5px;
  font-size: 12px;
  color: rgba(255,255,255,1);
}
.accordian-user-loc  {
  background-color: #80808080 !important;
  margin: 16px 0 0 25px !important;
}

.accordian-user-loc {
  background-color: #80808080 !important;
  margin: 16px 0 0 25px !important;
}

.message-text-counts {
  position: absolute;
  right: 6px;
  top: -20px;
  font-size: 14px;
  @media screen and (max-width: "767px") {
    bottom: -60px;
    top: unset;
  }
  &.limit {
    color: red;
  }
}

// lease popup css 24/5/2023
.lease-application-dailog {
  .MuiDialog-container {
    .MuiPaper-root {
      min-height: 100px;
      height: unset !important;
    }
  }
}

// Hide Steps from popup (31-05-2023)
.lease-application-dailog .applicant-step-label > .MuiStepLabel-iconContainer {
  display: none;
}

@media screen and (max-width: "767px") {
  .lease-application-dailog {
    .MuiDialog-container {
      .MuiPaper-root {
        width: 95%;
        margin: 10px;
      }
    }
  }
}

// SD-3241 css for hide info logo
.info-error{
  .MuiInput-root{
    &::after{
      right: 13px;
      width: 15px;
      height: 30px;
      left: unset;
      background: #ffffff;
      z-index: 0;
      transform: unset;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    svg{
      z-index: 1;
    }
  }
}

.img-sort-modal-open{
  .MuiModal-root, header, .MuiDrawer-paper, .fixed-button{
    z-index: 0;
  }
}

// SD-3360
.move-in-container {
  .ql-container {
    border: none !important;
  }
}

.anchorClass {
  color: rgb(26, 115, 232) !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.custom-file-size > div{
  padding: 20px 6px !important;
}
.filter-chip {
  margin-right: 5px !important;
  margin-left: 0px !important;
}

.profile-menu-item {
  margin: 0px !important;
}

// SD-3402
.custom-table-delay {
  border-collapse: collapse;
  width: 100%;
}

.custom-table-delay th, .custom-table-delay td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.custom-table-delay th {
  background-color: #f2f2f2;
}

.css-1oxi9x3-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  height: 10px;
}
.weekend-time-zone-picker {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.weekend-tilldate {
  display: flex;
  justify-content: flex-end;
}

.weekend-day {
  background-color: #d7d7d7;
  padding: 1px 2px;
  border-radius: 2px;
}

// 06-10-2023 css
.ticket_drop_image > div {
  margin: 0px !important;
}

// 10/10/2023 css
#activity-card {
  .activity-card-wrapper {
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
    padding: 20px;
    height: 605px;
    overflow: auto;
  }
}
#notes-card {
  .notes-card-wrapper {
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1),
      0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06);
    padding: 20px;
    .notes-card-grid {
      height: 410px;
      overflow: auto;
    }
  }
}
.ticket-image {
  height: 225px;
}
@media only screen and (min-width: 1700px) {
  #activity-card {
    .activity-card-wrapper {
      height: 585px;
    }
  }
  #notes-card {
    .notes-card-wrapper {
      .notes-card-grid {
        height: 390px;
      }
    }
  }
  .ticket-image {
    height: 205px;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.chip-list-item {
  margin-right: 5px;
  margin-top: 2px;
  min-height: 32px;
  white-space: normal !important;
  height: auto !important;
  & span {
    white-space: normal;
  }
}